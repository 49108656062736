<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="role.id && organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        class="cursor-pointer"
        :icon="isEditable ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        @click="changeEditable"
      />

    </div>
    <b-form
      @submit.prevent="submit"
    >
      <validation-observer
        ref="roleValidationRef"
      >
        <b-row>
          <b-col md="12">
            <div class="row">
              <b-col md="6">
                <b-form-group
                  label-class="inputLabel ml-1 mb-0"
                  label-cols-md="0"
                  label-align-md="left"
                  :label="$t('Role Name')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Role Name')"
                    rules="required"
                    vid="name"
                  >
                    <b-form-input
                      v-model="role.name"
                      class="col"
                      :disabled="isEditable"
                      placeholder="Type..."
                      @input="isNameOrStatusChange()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <span class="font-weight-bolder ml-sm-2">{{ $t('Status') }}</span>
                  <b-row class="d-flex align-items-center mt-1">
                    <b-col md="1">
                      <b-form-checkbox
                        v-model="role.is_active"
                        name="status"
                        style="padding: 10px; margin-top: 0"
                        class="align-self-center"
                        :class="`${isEditable ? 'check--disabled': ''}`"
                        :disabled="isEditable"
                        @input="isNameOrStatusChange()"
                      />
                    </b-col>
                    <b-col md="2">
                      <span>{{ $t('Active') }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </div>
            <div>
              <h3 class="mt-1 font-weight-bolder text-body">
                Permissions
              </h3>
            </div>
            <div
              v-for="permission in permissionList"
              :key="permission.id"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span class="font-weight-bolder font-medium-5 ml-1">
                  {{ permission.label }}
                </span>
                <div class="d-flex align-items-center">
                  <span />
                  <span
                    class="text-center flex-column align-items-center"
                    style="margin-right: 46px"
                  >
                    {{ $t('View') }}
                    <span
                      class="custom-control custom-checkbox text-center p-0"
                    >
                      <input
                        v-if="!permission.permissions.find(item => item.label === 'change').required"
                        :id="'view_' + permission.id"
                        type="checkbox"
                        :value="permission.permissions.find(item => item.label === 'view').id"
                        :disabled="isEditable"
                        :checked="checkboxStatus(permission, 'view')"
                        hidden
                        class="custom-checkbox custom-control main-checkbox"
                        @click="editMainCheckboxPermissions($event, permission, 'view')"
                      >
                      <label
                        v-if="!permission.permissions.find(item => item.label === 'change').required"
                        :for="'view_' + permission.id"
                        :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
                      />
                    </span>
                  </span>
                  <span
                    class="text-center d-flex flex-column align-items-center"
                    style="margin-right: 30px"
                  >
                    {{ $t('Change') }}
                    <span
                      class="custom-control custom-checkbox text-center p-0"
                    >
                      <input
                        v-if="!permission.permissions.find(item => item.label === 'change').required"
                        :id="'change_' + permission.id"
                        type="checkbox"
                        :value="permission.permissions.find(item => item.label === 'change').id"
                        :disabled="isEditable"
                        hidden
                        :checked="checkboxStatus(permission, 'change')"
                        class="custom-checkbox custom-control main-checkbox"
                        @click="editMainCheckboxPermissions($event, permission, 'change')"
                      >
                      <label
                        v-if="!permission.permissions.find(item => item.label === 'change').required"
                        :for="'change_' + permission.id"
                        :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div
                v-for="permissionSecond in permission.children"
                :key="permissionSecond.label"
              >
                <div class="d-flex align-items-center justify-content-between w-100 my-1">
                  <span
                    v-b-toggle="'group' + permissionSecond.id"
                    class="font-weight-bolder ml-1"
                  >
                    {{ permissionSecond.label }}
                    <feather-icon
                      icon="ChevronDownIcon"
                      class="when-opened"
                    />
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="when-closed"
                    />
                  </span>
                  <div class="d-flex align-items-center">
                    <span />
                    <span class="text-center flex-column align-items-center mr-5">
                      <span
                        class="custom-control custom-checkbox text-center p-0"
                        style="margin-right: 18px"
                      >
                        <input
                          v-if="!permissionSecond.permissions.find(item => item.label === 'change').required"
                          :id="'View_' + permissionSecond.id"
                          type="checkbox"
                          :value="permissionSecond.permissions.find(item => item.label === 'view').id"
                          :disabled="isEditable"
                          :checked="checkboxStatus(permissionSecond, 'view')"
                          hidden
                          class="custom-checkbox custom-control second-checkbox"
                          @click="editMainCheckboxPermissions($event, permissionSecond, 'view')"
                        >
                        <label
                          v-if="!permissionSecond.permissions.find(item => item.label === 'change').required"
                          :for="'View_' + permissionSecond.id"
                          :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
                        />
                      </span>
                    </span>
                    <span
                      class="text-center d-flex flex-column align-items-center"
                      style="margin-right: 30px"
                    >
                      <span
                        class="custom-control custom-checkbox text-center p-0"
                        style="margin-right: 15px"
                      >
                        <input
                          v-if="!permissionSecond.permissions.find(item => item.label === 'change').required"
                          :id="'Change_' + permissionSecond.id"
                          type="checkbox"
                          :value="permissionSecond.permissions.find(item => item.label === 'change').id"
                          :disabled="isEditable"
                          hidden
                          :checked="checkboxStatus(permissionSecond, 'change')"
                          class="custom-checkbox custom-control second-checkbox"
                          @click="editMainCheckboxPermissions($event, permissionSecond, 'change')"
                        >
                        <label
                          v-if="!permissionSecond.permissions.find(item => item.label === 'change').required"
                          :for="'Change_' + permissionSecond.id"
                          :class="`role_select_checkbox ${isEditable ? 'check--disabled': ''}`"
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <b-collapse
                  :id="'group' + permissionSecond.id"
                  :visible="Boolean(permissionSecond.children.length)"
                >
                  <div class="table-responsive position-relative">
                    <table
                      v-if="permission.children.length > 0"
                      class="table b-table table-striped"
                    >
                      <tbody>
                        <permission-table-component
                          v-for="perm in permissionSecond.children"
                          :key="perm.id"
                          :is-editable="isEditable"
                          :permission="perm"
                          @addPermissions="editMainCheckboxPermissions"
                        />
                      </tbody>
                    </table>
                  </div>
                </b-collapse>
              </div>
            </div>
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings-users-role-list' })"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BCollapse,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
import { localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import { scrollToError } from '@core/utils/utils'
import store from '@/store'
import PermissionTableComponent from './PermissionTableComponent.vue'

export default {
  name: 'FormComponent',
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BCollapse,
    PermissionTableComponent,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    role: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      permissions: this.$props.role?.permissions ? [...this.$props.role?.permissions] : [],
      storePermissionsList: this.$store.state.listModule?.permissionList,
      propsRolePermissionIds: this.$props.role?.permissions ?? [],
      storePermissionsIds: [],
      isSelectedAll: false,
      viewCheckboxIds: [],
      isEditable: store.state.app.organizationRequiresEditConfirmation,
      trigger: 0,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    permissionList() {
      // eslint-disable-next-line no-unused-expressions
      const markChecked = permissionGroup => {
        permissionGroup.forEach(permission => {
          // eslint-disable-next-line array-callback-return
          permission.permissions.filter(item => {
            this.storePermissionsIds.push(item.id)
            if (this.propsRolePermissionIds.includes(item.id)) {
              // eslint-disable-next-line no-param-reassign
              item.checked = true
            }
          })
          if (permission.children.length > 0) {
            markChecked(permission.children)
          }
        })
      }
      markChecked(this.$store.state.listModule?.permissionList)
      return this.$store.state.listModule.permissionList
    },
    roleForm() {
      return this.$store.state[this.MODULE_NAME].roleForm
    },
    roleClone() {
      return this.$store.state.cloneData.role
    },
    isFormChanged() {
      this.trigger
      // if (!this.role?.id) return
      const parsedClone = JSON.parse(this.roleClone)
      if (!this.role?.id) {
        if (this.role?.name !== undefined && this.role?.name !== '') {
          return true
        }

        if (this.role?.is_active !== undefined && this.role?.is_active !== false) {
          return true
        }
        if (this.permissions.length > 0) {
          return true
        }

        return false
      }
      if (this.permissions.length !== parsedClone.permissions.length
          || !this.checker(this.permissions, parsedClone.permissions)
      ) {
        // eslint-disable-next-line consistent-return
        return true
      }
      if (this.role?.name !== parsedClone.name) {
        // eslint-disable-next-line consistent-return
        return true
      }
      if (this.role?.is_active !== parsedClone.is_active) {
        // eslint-disable-next-line consistent-return
        return true
      }
      // eslint-disable-next-line consistent-return
      return false
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
  mounted() {
    if (!this.role.id) this.isEditable = false
  },
  methods: {
    isNameOrStatusChange() {
      this.trigger += 1
    },
    resetPermissionsToInputDefault(permissionList, defaultValue = undefined) {
      permissionList.forEach(i => {
        if (i.children.length > 0) {
          this.resetPermissionsToInputDefault(i.children, defaultValue)
        }
        i.permissions.forEach(c => {
          if (document.getElementById(`item_${c.id}`)) {
            document.getElementById(`item_${c.id}`).checked = defaultValue === undefined ? JSON.parse(this.roleClone).permissions.includes(Number(document.getElementById(`item_${c.id}`).value)) : defaultValue
            // return true
          }
          if (document.getElementById(`View_${c.group_id}`)) {
            document.getElementById(`View_${c.group_id}`).checked = defaultValue === undefined ? JSON.parse(this.roleClone).permissions.includes(Number(document.getElementById(`View_${c.group_id}`).value)) : defaultValue
          }
          if (document.getElementById(`Change_${c.group_id}`)) {
            document.getElementById(`Change_${c.group_id}`).checked = defaultValue === undefined ? JSON.parse(this.roleClone).permissions.includes(Number(document.getElementById(`Change_${c.group_id}`).value)) : defaultValue
          }
        })
      })
    },
    resetPermissionsToDefault() {
      if (!this.role?.id) {
        this.resetPermissionsToInputDefault(this.permissionList, false)
      } else {
        this.resetPermissionsToInputDefault(this.permissionList)
      }
    },
    checkboxStatus(permission, checkboxLabel) {
      return permission.permissions.find(item => item.label === checkboxLabel).checked
    },
    editMainCheckboxPermissions(event, permission, checkboxLabel) {
      const firstCheckbox = document.getElementsByClassName('main-checkbox')
      const secondCheckbox = document.getElementsByClassName('second-checkbox')
      const thirdCheckbox = document.getElementsByClassName('children-checkbox')
      const permArr = []
      permArr.push(permission)
      const permissionsIdArr = []
      const getChildrenId = permissionGroup => {
        // eslint-disable-next-line no-restricted-syntax
        permissionGroup.forEach(permItem => {
          const permLabel = permItem.permissions.find(item => item.label === checkboxLabel)
          const viewLabel = permItem.permissions.find(item => item.label === 'view')
          if (typeof permLabel === 'object' && permLabel !== null) {
            permissionsIdArr.push(permLabel.id)
            if (checkboxLabel === 'change' && event.target.checked === true) {
              permissionsIdArr.push(viewLabel.id)
            }
          }
          if (permItem.children.length > 0) {
            getChildrenId(permItem.children)
          }
        })
        return permissionsIdArr
      }
      getChildrenId(permArr)
      if (event.target.checked) {
        // eslint-disable-next-line no-restricted-syntax
        for (const element of firstCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            this.permissions.push(Number(element.value))
            element.checked = true
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of secondCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            this.permissions.push(Number(element.value))
            element.checked = true
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of thirdCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            this.permissions.push(Number(element.value))
            element.checked = true
          }
        }
      } else if (checkboxLabel === 'view' && event.target.checked === false) {
        const toRemove = []
        // eslint-disable-next-line no-shadow
        const getChildrenId = permissionGroup => {
          // eslint-disable-next-line no-restricted-syntax
          permissionGroup.forEach(permItem => {
            const changeLabel = permItem.permissions.find(item => item.label === 'change')
            if (typeof changeLabel === 'object' && changeLabel !== null) {
              permissionsIdArr.push(changeLabel.id)
            }
            if (permItem.children.length > 0) {
              getChildrenId(permItem.children)
            }
          })
          return permissionsIdArr
        }
        getChildrenId(permArr)
        // eslint-disable-next-line no-restricted-syntax
        for (const element of firstCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of secondCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of thirdCheckbox) {
          // this.permissions = this.permissions.filter(item => item !== Number(element.value))
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        this.permissions = this.permissions.filter(el => !toRemove.includes(el))
      } else {
        const toRemove = []
        // eslint-disable-next-line no-restricted-syntax
        for (const element of firstCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of secondCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const element of thirdCheckbox) {
          if (permissionsIdArr.includes(Number(element.value))) {
            toRemove.push(Number(element.value))
            element.checked = false
          }
        }
        this.permissions = this.permissions.filter(el => !toRemove.includes(el))
      }

      this.permissions = [...new Set(this.permissions)]
    },
    changeEditable() {
      this.isEditable = !this.isEditable
    },
    loader() {
      this.$refs.roleValidationRef.reset()
      if (!this.role?.id) {
        this.$store.commit('roles/GET', { is_active: false })
        this.permissions = []
        this.resetPermissionsToDefault()
      } else {
      // this.$emit('cancel')
        this.permissions = JSON.parse(this.roleClone).permissions
        this.$store.commit('roles/SET_FORM', JSON.parse(this.roleClone))
        this.resetPermissionsToDefault()
        this.role.name = JSON.parse(this.roleClone).name
        this.role.is_active = JSON.parse(this.roleClone).is_active
      }
    },
    resetPermissions() {
      if (this.propsRolePermissionIds) {
        // eslint-disable-next-line array-callback-return
        this.storePermissionsIds.filter(storePermissionsId => {
          if (this.propsRolePermissionIds.includes(storePermissionsId)) {
            // eslint-disable-next-line no-unused-expressions
            this.$store.state.listModule?.permissionList.forEach(permission => {
              // eslint-disable-next-line array-callback-return
              permission.permissions.filter(item => {
                // eslint-disable-next-line no-param-reassign
                item.checked = false
              })
            })
          }
        })
      }
    },
    submit() {
      if (typeof this.role.is_active === 'undefined') {
        this.role.is_active = false
      }
      this.role.permissions = [...new Set(this.permissions)]
      this.$refs.roleValidationRef.validate()
        .then(success => {
          if (success) {
            this.$refs.roleValidationRef.reset()
            this.$emit('submit', this.role, this.resetPermissions)
          } else {
            scrollToError(this, this.$refs.roleValidationRef)
          }
        })
    },
    cancel() {
      this.$refs.roleValidationRef.reset()
      this.$emit('cancel')
      this.resetPermissions()
    },
    checker(arr, target) {
      return target.every(v => arr.includes(v))
    },
  },
  setup() {
    const MODULE_NAME = 'roles'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.custom-checkbox {
  height: 15px;
  width: 15px;
  margin-left: 10px;
}

.custom-control-label::after {
  background-color: #efefef;
  border-radius: 2px;
  opacity: 0.5;
  width: 1px;
  height: 1px;
}

.check--disabled {
  background-color: #efefef;
  border-radius: 5px;
  opacity: 0.5;
}
</style>
